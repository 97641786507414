import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';

export interface UseSearchInterface {
    query: string
    searchQuery: string
    setQuery: (query: string) => void
    actualPath: string
    setActualPath: (path: string) => void
    handleFindBy: (path: string) => void
    setSearchQuery: (query: string) => void
}

export const useSearch = (): UseSearchInterface => {
    const [query, setQuery] = useState("")
    const [searchQuery, setSearchQuery] = useState("")
    const [actualPath, setActualPath] = useState('/')
    const navigate = useNavigate()
    
    const handleFindBy = (path: string): void => {
        saveSearch(query, path)
        if (path === 'peticoes') {
          setActualPath('/peticoes')
        } else if (path === 'clientes') {
          setActualPath('/clientes')
        } else if (path === 'jurisprudencia') {
          setActualPath('/jurisprudencia')
        }
        if (navigate) {
           navigate(`/${path}`)
        }
    }

    const saveSearch = (search: string, where: string): void => {
        setSearchQuery(query)
        const searchHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]') || Array(5)
        const uniqueHistory = searchHistory?.filter(
            (item: { search: string, where: string }) => {
            return !(
                item.search.toLowerCase() === search.toLowerCase() &&
                item.where === where
            )
            }
        )

        uniqueHistory?.unshift({ search, where })
        if (uniqueHistory?.length > 5) {
            uniqueHistory?.pop()
        }
        localStorage.setItem('searchHistory', JSON.stringify(uniqueHistory))
    }

    return {
        query,
        setQuery,
        actualPath,
        setActualPath,
        handleFindBy,
        searchQuery,
        setSearchQuery
    }
}