/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { SearchContext } from '../contexts/SearchContext';
import { usePrevTheme } from '@prev-front/ui-components';

function ChildAppPlaceholder({ appName }) {
  const contextValue = useContext(SearchContext);
  const [lastApp, setLastApp] = useState<any>()
  const [lastName, setLastName] = useState<any>()
  const { toggleDarkMode } = usePrevTheme();

  // useEffect(() => {
  //   loadChildApp();
  //   // // Limpeza do efeito colateral
  //   // return () => {
  //   //   if (lifecycles && document.getElementById(appName)) {
  //   //     lifecycles.unmount({
  //   //       domElement: document.getElementById(appName),
  //   //       context: { ...contextValue, toggleDarkMode },
  //   //     });
  //   //   }
  //   // };
  // }, []);


  useEffect(() => {
    loadChildApp()
  }, [appName, contextValue.searchQuery, toggleDarkMode])


  const loadChildApp = async () => {
    try {
    if(!appName) return
    // alert(appName)
    // alert(lastName)
    if(lastApp && lastName) {
      await lastApp?.unmount({
          domElement: document.getElementById(lastName),
          context: { ...contextValue, toggleDarkMode },
      });
    }
      // Importa o aplicativo filho
      const newApp = await System.import(appName);
      // Monta o aplicativo filho
      await newApp.mount({
        domElement: document.getElementById(appName),
        context: { ...contextValue, toggleDarkMode },
      });
      setLastApp(newApp)
      setLastName(appName)
    } catch (err) {
      console.error("Error loading child app", err);
    }
  };


  return <div id={appName} />;
}

export default ChildAppPlaceholder;