import React, { useContext } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { type UseSearchInterface } from "./hooks/useSearch"

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  // @ts-expect-error pagina de erro //
  errorBoundary () {
    // Customize the root error boundary for your microfrontend here.
    return null
  }
})

export const { bootstrap, mount, unmount } = lifecycles
export { UseSearchInterface }