import React, { useEffect, useRef, useState } from 'react'
import { Link, navigate as navigateReach } from '@reach/router'
import '../styles.css'
import {
  Layout,
  Menu,
  Button,
  Text,
  PrevLogo,
  Row,
  Col,
  Searchbar,
  Avatar,
  Dropdown,
  Modal,
  styled,
  usePrevTheme
} from '@prev-front/ui-components'
import PaywallLogo1 from '../assets/prevCasosPaywall.svg'
import PaywallLogo2 from '../assets/prevCasosPaywall2.svg'

const moduleMap = {
  LAUDOS: 'Banco de laudos',
  CASOS: 'Prev Casos',
  DIRETORIO: 'Diretório de Advogados'
}

const moduleLink = {
  LAUDOS: 'https://previdenciarista.com/banco-de-laudos-produto/',
  CASOS: 'de Casos',
  DIRETORIO: 'do Diretório de Advogados'
}

const paywallText = {
  LAUDOS:
    'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.',
  CASOS:
    'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.',
  DIRETORIO:
    'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.'
}

const regexCasos = /^CASOS_\d+$/
const regexLaudos = /^LAUDOS_\d+$/

import {
  TeamOutlined,
  FileTextOutlined,
  HomeOutlined,
  BankOutlined,
  SolutionOutlined,
  PlusCircleOutlined,
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  WalletOutlined,
  MenuFoldOutlined,
  BookOutlined,
  UnorderedListOutlined,
  QuestionCircleOutlined,
  CalculatorOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  MedicineBoxOutlined,
  FileSyncOutlined
} from '@ant-design/icons'
import { getInitials, getProfile, getSlugFromUrl } from '../utils'
import { getUserInfo } from '@prev-front/api-adapter'
import { useSearch } from '../hooks/useSearch'
import { SearchContext } from '../contexts/SearchContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { LoginModal } from '../components/LoginModal'

interface siderItems {
  icon: any
  displayName: string
  link?: string
  options?: any[]
  key?: string
}

const checkBarraRouteException = (): string | undefined => {
  const listaException = [
    'invitation-accepted',
    'password-recovery',
    'login-escritorio',
    'migracao',
    'login',
    'criacaoConta',
    'checkout',
    'contribuicoes',
    'reativarAssinatura',
    'aula/',
    'pagarFatura',
    'meu-escritorio',
    'pagar-fatura'
  ]
  const temException = listaException.find((item) =>
    window.location.href.includes(item)
  )
  return temException
}

interface ILayout {
  children: any
  siderWidth?: number
  siderItems?: siderItems[]
  onSearch?: (inputRef: any) => void
  onNewCalculation?: () => void
  profileBadgeCount?: number
  navigate?: any
}

const { Header, Content, Sider } = Layout
const StyledSider = styled(Sider)`
  && {
    padding: 0;
    border: none;
    min-height: 100vh;
    background-color: ${(props) => props.theme.color.surface.container.default};
  }
`
const StyledMenu = styled(Menu)`
  && .ant-menu-submenu-selected > .ant-menu-submenu-title,
  && .ant-menu-item-selected {
    border-radius: 0;
    height: 48px;
    color: ${(props) => props.theme.color.text.brand} !important;
    background-color: transparent;
  }
  && .ant-menu-item-active:hover,
  && .ant-menu-submenu-title:hover,
  && .ant-menu-item:not(.ant-menu-item-selected):hover,
  &&
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    border-radius: 0;
    opacity: 0.6;
  }

  && .ant-menu-sub.ant-menu-inline {
    background: none;
  }
  .ant-menu-item {
    height: 48px;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-item-selected {
    border-left: 2px solid ${(props) => props.theme.color.text.brand};
  }
`

const StyledContent = styled(Content)`
  padding: 27px 30px;
`

export const PrevLayout = ({
  children,
  siderWidth = 240,
  siderItems = [
    { icon: HomeOutlined, displayName: 'Painel', link: '/', key: '/' },
    {
      icon: TeamOutlined,
      displayName: 'Clientes',
      link: '/clientes',
      key: '/clientes'
    },
    {
      icon: FileTextOutlined,
      displayName: 'Busca de petições',
      link: '/peticoes',
      key: '/peticoes'
    },
    {
      icon: BankOutlined,
      displayName: 'Busca de jurisprudência',
      link: '/jurisprudencia',
      key: '/jurisprudencia'
    },
    {
      icon: FileSyncOutlined,
      displayName: 'Busca de laudos',
      link: '/laudos',
      key: '/laudos'
    },
    {
      icon: SolutionOutlined,
      displayName: 'Casos',
      link: '/casos',
      key: '/casos'
    },
    {
      icon: UnorderedListOutlined,
      displayName: 'Meu diretório',
      link: '/meu-escritorio',
      key: '/meu-escritorio'
    },
    {
      icon: BookOutlined,
      displayName: 'Cursos',
      link: '/cursos',
      key: '/cursos'
    },
    {
      icon: QuestionCircleOutlined,
      displayName: 'Central de ajuda',
      link: 'https://ajuda.previdenciarista.com/',
      key: 'https://ajuda.previdenciarista.com/'
    }
  ],
  onSearch,
  onNewCalculation,
  profileBadgeCount,
  navigate = null
}: ILayout): JSX.Element => {
  const [selected, setSelected] = useState<string[]>(['/'])
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 1200)
  const [userInfo, setUserInfo] = useState<any | null>()
  const [barContent, setBarContent] = useState<JSX.Element | undefined>()
  const [modules, setModules] = useState<string[]>([])
  const {
    actualPath,
    handleFindBy,
    query,
    setActualPath,
    setQuery,
    searchQuery,
    setSearchQuery
  } = useSearch()
  const containerRef = useRef<HTMLDivElement>(null) // Adicionar referência ao contêiner
  const [paywall, setPaywall] = useState({
    open: false,
    reason: ''
  })
  const [statusOk, setStatusOk] = useState(true)
  const [checkedSlug, setCheckedSlug] = useState(false)
  const navigateDom = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { darkMode, theme } = usePrevTheme()

  const checkSlug = () => {
    const hasSlug = getSlugFromUrl()
    if (hasSlug || (userInfo && !userInfo.escritorio)) {
      setCheckedSlug(true)
    }
    if (!hasSlug && userInfo && userInfo.escritorio?.slug) {
      navigateReach('/login')
    }
  }

  useEffect(() => {
    checkSlug()
  }, [userInfo])

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.backgroundColor =
      theme.color.surface.container.default
  }, [darkMode])

  const scrollTop = (): void => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const items = siderItems.map(
    ({ icon, displayName, options, link, key }, index) => {
      const MenuIcon = icon
      return {
        key: key ?? index,
        icon: <MenuIcon style={{ color: theme.color.text.brand }} />,
        label: (
          <a
            onClick={() => {
              if (link === 'https://ajuda.previdenciarista.com/') {
                window.open(link, '_blank')
                return
              }
              if (!link || !navigateDom) return
              link === '/meu-escritorio'
                ? window.open('/meu-escritorio', '_blanck')
                : navigateDom(link ?? '')
            }}
          >
            <Text
              style={{
                color:
                  selected[0] === key
                    ? theme.color.text.brand
                    : theme.color.text.default
              }}
              emphasis={selected[0] === key}
            >
              {displayName}
            </Text>
          </a>
        ),
        children: options?.map(({ displayName }, j) => {
          const subKey = index * 4 + j + 1
          return {
            key: subKey,
            label: (
              <Text
                onClick={() => {
                  if (link === 'https://ajuda.previdenciarista.com/') {
                    window.open(link, '_blank')
                    return
                  }
                  navigateDom(link ?? '')
                }}
              >
                {displayName}
              </Text>
            )
          }
        })
      }
    }
  )

  const setupProfile = async (): Promise<void> => {
    try {
      const data = await getProfile()
      const userInfo = await getUserInfo()
      const modules = userInfo?.assinatura.tiposDeModulo
      if (modules?.length) {
        setModules(modules)
      }
      setUserInfo(data as unknown as any)
      void renderBarras()
    } catch (err) {
      console.log('Erro ao buscar informações do usuário', err)
    }
  }

  const checkPaywall = (module?: string): boolean => {
    if (!statusOk) {
      setPaywall({
        open: true,
        reason: 'PAYMENT'
      })
      return statusOk
    }
    let hasModule = module ? false : true
    if (module && modules.some((m) => m.startsWith(module))) {
      hasModule = true
    }
    if (!hasModule) {
      setPaywall({
        open: true,
        reason: module || 'PAYMENT'
      })
    }
    return statusOk && hasModule
  }

  useEffect(() => {
    const currentUrl = pathname
    if (
      [
        '/peticoes',
        '/jurisprudencia',
        '/clientes',
        '/laudos',
        '/casos',
        '/cursos',
        '/'
      ].includes(currentUrl)
    ) {
      setSelected([currentUrl])
      setActualPath(currentUrl)
    }
  }, [pathname])

  const renderBarras = async (): Promise<JSX.Element | null | undefined> => {
    try {
      const info = await getUserInfo()
      if (!info?.assinatura || checkBarraRouteException()) return null
      const { status, acesso } = info.assinatura
      const suspenso = status === 'ATIVA' && acesso === 'SUSPENSO'
      const cancelada = status === 'CANCELADA' && acesso !== 'ATIVO'
      if (window.location.pathname.includes('/casos/')) return
      if (suspenso || cancelada) {
        setStatusOk(false)
      }
      if (suspenso) {
        setBarContent(
          <Row
            style={{
              width: '100%',
              backgroundColor: '#ec1051',
              gap: '25px',
              height: '70px'
            }}
            justify="center"
            align="middle"
          >
            <Text style={{ color: 'white' }}>
              Atenção! Seu plano possui pendências financeiras. Corrija para
              restabelecer o acesso.
            </Text>
            <Button
              style={{ color: 'white' }}
              type="secondary"
              onClick={() => {
                navigateReach('/configuracoes/financeiro')
              }}
            >
              Visualizar pendências
            </Button>
          </Row>
        )
      } else if (cancelada) {
        setBarContent(
          <Row
            style={{
              width: '100%',
              backgroundColor: '#ff8822',
              gap: '25px',
              height: '70px'
            }}
            justify="center"
            align="middle"
          >
            <Text style={{ color: 'white' }}>
              Atenção! Você não tem uma assinatura ativa. Reative sua assinatura
              aqui para restabelecer seu acesso.
            </Text>
            <Button
              style={{ color: 'white' }}
              type="secondary"
              onClick={() => {
                navigateReach('/configuracoes/financeiro')
              }}
            >
              Ativar assinatura
            </Button>
          </Row>
        )
      }
    } catch (err) {
      console.log('Erro ao verificar status assinatura')
    }
  }

  const calculoDropdown = (
    <Menu>
      <Menu.Item
        key="1"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/calculos/beneficio')}
      >
        <Row style={{ gap: '5px' }}>
          <CalculatorOutlined />
          <Text emphasis>Benefícios previdenciários</Text>
        </Row>
        <Text>Faça o envio do CNIS ou inicie o cálculo manualmente</Text>
      </Menu.Item>
      <Menu.Item
        key="2"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/calculos/liquidacao')}
      >
        <Row style={{ gap: '5px' }}>
          <FileDoneOutlined />
          <Text emphasis>Liquidação de sentença</Text>
        </Row>
        <Text>Realize um cálculo dos benefícios atrasados</Text>
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/calculos/valor-causa')}
      >
        <Row style={{ gap: '5px' }}>
          <DollarCircleOutlined />
          <Text emphasis>Valor da causa</Text>
        </Row>
        <Text>Realize um cálculo do valor da causa</Text>
      </Menu.Item>
      <Menu.Item
        key="4"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/beneficio-por-incapacidade')}
      >
        <Row style={{ gap: '5px' }}>
          <MedicineBoxOutlined />
          <Text emphasis>Benefício por incapacidade</Text>
        </Row>
        <Text>
          Fluxo de concessão ou restabelecimento de benefícios por incapacidade
          para processo eletrônico
        </Text>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    void setupProfile()
  }, [])

  return (
    <SearchContext.Provider
      value={{
        query,
        setQuery,
        setSearchQuery,
        actualPath,
        setActualPath,
        handleFindBy,
        scrollTop,
        checkPaywall,
        searchQuery
      }}
    >
      <Layout
        className="layoutPrev"
        style={{ position: 'fixed', width: '100%', zIndex: 50 }}
      >
        <Header
          style={{
            height: barContent ? '140px' : '64px',
            padding: 0,
            margin: 0,
            backgroundColor: theme.color.surface.default,
            borderBottom: `1px solid ${theme.color.border.default}`
          }}
        >
          {barContent}
          <Row
            style={{
              width: '100%',
              height: '70px',
              paddingRight: '10px',
              paddingLeft: '10px'
            }}
            align="middle"
            justify="space-between"
          >
            <Col span={2}>
              <PrevLogo />
            </Col>
            <Col span={13}>
              <Searchbar
                data-testid="inpt-header-searchbar"
                actualPath={actualPath as '/'}
                clearSearch={() => {
                  setQuery('')
                  setSearchQuery("")
                }}
                handleChange={setQuery}
                value={query}
                searchQuery={query}
                setActualPath={setActualPath}
                showModal={() => true}
                showHistory={true}
                findBy={handleFindBy}
              />
            </Col>
            <Col style={{ marginRight: '32px' }}>
              <Dropdown
                overlay={() => (
                  <Menu
                    style={{ backgroundColor: theme.color.surface.default }}
                  >
                    <Menu.Item key="1" data-testid="btn-header-menu-conta">
                      <Link to="/configuracoes/conta">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <UserOutlined />
                          <Text>Minha conta</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" data-testid="btn-header-menu-financeiro">
                      <Link to="/configuracoes/financeiro">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <WalletOutlined />
                          <Text>Meu financeiro</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      data-testid="btn-header-menu-configuracoes"
                    >
                      <Link to="/configuracoes">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <WalletOutlined />
                          <Text>Configurações do escritório</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      data-testid="btn-header-menu-sair"
                      key="4"
                      onClick={() => {
                        localStorage.removeItem('access_token_prev')
                        navigateReach('/login')
                      }}
                    >
                      <Row
                        style={{ gap: '5px', color: theme.color.text.default }}
                      >
                        <LogoutOutlined />
                        <Text>Sair do sistema</Text>
                      </Row>
                    </Menu.Item>
                  </Menu>
                )}
              >
                <Row gutter={12} align="middle" justify="end">
                  <Col>
                    <Avatar
                      src={userInfo?.usuario?.urlImagemPerfil}
                      size={32}
                      data-testid="header-avatar-usuario"
                      shape="circle"
                      style={{ cursor: 'pointer' }}
                    >
                      {getInitials(userInfo?.usuario.nome ?? '')}
                    </Avatar>
                  </Col>
                  <Col style={{ height: '100%', cursor: 'pointer' }}>
                    <Row
                      style={{
                        justifyContent: 'space-between',
                        marginBottom: '10px'
                      }}
                    >
                      <Text
                        style={{ marginRight: '5px' }}
                        data-testid="header-nome-usuario"
                      >
                        {userInfo?.usuario.nome}
                      </Text>
                      <DownOutlined
                        style={{ color: theme.color.text.default }}
                      />
                    </Row>
                    <Row>
                      <Text
                        data-testid="header-nome-escritorio"
                        color={theme.color.text.subtle}
                      >
                        {userInfo?.escritorio?.nome}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Layout>
          <StyledSider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={siderWidth}
            style={{
              borderRight: `1px solid ${theme.color.border.default}`,
              backgroundColor: theme.color.surface.default
            }}
          >
            <div
              style={{
                display: 'flex',
                height: 'max-content',
                justifyContent: 'center',
                marginTop: '40px'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '45px',
                  zIndex: 50,
                  right: '-30px',
                  backgroundColor: theme.color.surface.default,
                  padding: '8px',
                  boxShadow:
                    '0px 8px 12px 0px rgba(39, 39, 46, 0.15), 0px 0px 1px 0px rgba(39, 39, 46, 0.30)',
                  borderRadius: '4px',
                  border: `1px solid ${theme.color.border.default}`,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setCollapsed(!collapsed)
                }}
              >
                <MenuFoldOutlined
                  style={{ color: theme.color.text.default, fontSize: '16px' }}
                />
              </div>
              <Dropdown overlay={calculoDropdown} placement="bottomRight">
                <Button
                  type="primary"
                  style={{
                    width: '85%',
                    height: '45px',
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    if (
                      onNewCalculation !== undefined &&
                      onNewCalculation !== null
                    )
                      onNewCalculation()
                  }}
                >
                  <PlusCircleOutlined
                    style={{ color: 'white', fontSize: theme.fontSize.h6 }}
                  />
                  {!collapsed && (
                    <Text color="white" size="h6">
                      Novo cálculo
                    </Text>
                  )}
                </Button>
              </Dropdown>
            </div>
            <StyledMenu
              mode="inline"
              style={{
                marginTop: 10,
                border: 'none',
                backgroundColor: theme.color.surface.default
              }}
              items={items}
              selectedKeys={selected}
            />
          </StyledSider>
          <StyledContent
            ref={containerRef}
            style={{
              overflowY: 'scroll',
              height: '100vh',
              paddingBottom: '100px',
              backgroundColor: theme.color.surface.container.default
            }}
          >
            <span id="prev-container">
              <Modal
                width={600}
                type={paywall.reason !== 'PAYMENT' ? 'info' : 'warning'}
                title={
                  paywall.reason !== 'PAYMENT' ? (
                    <Text>
                      Faça um upgrade em seu plano para ter acesso a este
                      conteúdo
                    </Text>
                  ) : (
                    'Atenção'
                  )
                }
                closable={true}
                open={paywall.open}
                onClose={() =>
                  setPaywall({
                    open: false,
                    reason: ''
                  })
                }
                onCancel={() =>
                  setPaywall({
                    open: false,
                    reason: ''
                  })
                }
                footer={
                  paywall.reason === 'PAYMENT'
                    ? [
                        <Button
                          key="painelPaywall"
                          type="subtle"
                          onClick={() => {
                            setPaywall({
                              open: false,
                              reason: ''
                            })
                            navigateReach('/')
                          }}
                        >
                          Voltar para o painel
                        </Button>,
                        <Button
                          key="financeiroPaywall"
                          style={{ color: 'white' }}
                          type="primary"
                          onClick={() => {
                            setPaywall({
                              open: false,
                              reason: ''
                            })
                            navigateReach('/configuracoes/financeiro')
                          }}
                        >
                          Regularizar pendência
                        </Button>
                      ]
                    : [
                        <Button
                          key="painelPaywall"
                          type="primary"
                          style={{ color: 'white' }}
                          onClick={() => {
                            setPaywall({
                              open: false,
                              reason: ''
                            })
                            navigateReach('/configuracoes/financeiro')
                          }}
                        >
                          Configurar meu plano
                        </Button>,
                        <Button
                          key="financeiroPaywall"
                          style={{ color: 'white' }}
                          type="secondary"
                          onClick={() => {
                            setPaywall({
                              open: false,
                              reason: ''
                            })
                            window.open(moduleLink[paywall.reason], '_blank')
                          }}
                        >
                          Conheça {moduleMap[paywall.reason]}
                        </Button>
                      ]
                }
              >
                {paywall.reason === 'PAYMENT' && (
                  <Col style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <img
                      src={
                        paywall.reason !== 'PAYMENT'
                          ? PaywallLogo2
                          : PaywallLogo1
                      }
                    />
                    <Text
                      emphasis
                      style={{ marginBottom: '20px', marginTop: '20px' }}
                      size="h6"
                    >
                      Seu plano atual está suspenso por problemas financeiros.
                    </Text>
                    <Text style={{ marginBottom: '30px' }} emphasis>
                      Para que você possa utilizar nosso sistema normalmente,
                      precisamos que regularize esta pendência.
                    </Text>
                    <Text style={{ marginBottom: '10px' }}>
                      Isso pode ter acontecido porque:
                    </Text>
                    <Text color={theme.color.text.subtlest}>
                      1. Há algum problema com seu banco;
                    </Text>
                    <Text color={theme.color.text.subtlest}>
                      2. O cartão informado para pagamento está vencido;
                    </Text>
                    <Text color={theme.color.text.subtlest}>
                      3. Não há saldo suficiente em sua conta;
                    </Text>
                  </Col>
                )}
                {paywall.reason !== 'PAYMENT' && (
                  <Col style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <img
                      src={
                        paywall.reason !== 'PAYMENT'
                          ? PaywallLogo2
                          : PaywallLogo1
                      }
                    />
                    <Text
                      emphasis
                      style={{ marginBottom: '20px', marginTop: '20px' }}
                      size="h6"
                    >
                      Você não possui o módulo do {moduleMap[paywall.reason]}
                    </Text>
                    <Text style={{ marginBottom: '30px' }} emphasis>
                      {paywallText[paywall.reason]}
                    </Text>
                  </Col>
                )}
              </Modal>
              {checkedSlug && children}
            </span>
          </StyledContent>
        </Layout>
      </Layout>
    </SearchContext.Provider>
  )
}
