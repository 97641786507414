// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-input-affix-wrapper {
    padding: 0px 10px;
}

.ant-layout-header {
    line-height: 0;
}

.ant-input-outlined {
    background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".ant-input-affix-wrapper {\n    padding: 0px 10px;\n}\n\n.ant-layout-header {\n    line-height: 0;\n}\n\n.ant-input-outlined {\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
