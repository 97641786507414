// template-app/src/Root.js
import { PrevPlatformProvider } from '@prev-front/ui-components'
import { PrevLayout } from './pages/LayoutPrev'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from 'react-router-dom'
import ChildAppPlaceholder from './pages/ChildAppPlaceholder'
import React, { Suspense, useEffect, useState } from 'react'
import { activateInterceptors, getToken } from '@prev-front/api-adapter'
import { LoginModal } from './components/LoginModal'
import { FormAnimationProvider } from './contexts/FormAnimationContext'
import { UseSearchInterface } from './prev-front-mf-template'

export interface RootProps {
  context: UseSearchInterface  & { scrollTop: () => void, checkPaywall: () => boolean, toggleDarkMode: () => void }
}

export default function Root(props: RootProps) {
  const { context } = props
  const token = getToken()
  const [relogin, setRelogin] = useState(false)

  const handleRelogin = (state: boolean) => {
    setRelogin(state)
  }
  
  activateInterceptors(handleRelogin)

  if (!token && !relogin) {
    window.location.href = '/login'
    return <></>
  }

  return (
        <Router >
            <PrevPlatformProvider>
              <Suspense>
                  <FormAnimationProvider searchContext={props.context} >
                    <PrevLayout>
                      <LoginModal open={relogin} openLoginModal={setRelogin}/>
                        {
                          !relogin && (
                            <Routes >
                              <Route path="/" element={<Layout />}>
                                {/* mf-dashboard */}
                                <Route path="/" element={<ChildAppPlaceholder appName="@prev-front/mf-dashboard" />} />
                                <Route path="/configuracoes" element={<ChildAppPlaceholder appName="@prev-front/mf-dashboard" />} />
                                <Route path="/configuracoes/conta" element={<ChildAppPlaceholder appName="@prev-front/mf-dashboard" />} />
                                <Route path="/configuracoes/financeiro" element={<ChildAppPlaceholder appName="@prev-front/mf-dashboard" />} />
                                <Route path="/cursos" element={<ChildAppPlaceholder appName="@prev-front/mf-dashboard" />} />
                                {/* mf-clientes */}
                                <Route path="/clientes" element={<ChildAppPlaceholder appName="@prev-front/mf-clientes" />} />
                                <Route path="/clientes/:cpf" element={<ChildAppPlaceholder appName="@prev-front/mf-clientes" />} />
                                {/* mf-peticoes */}
                                <Route path="/jurisprudencia" element={<ChildAppPlaceholder appName="@prev-front/mf-peticoes" />} />
                                <Route path="/peticoes" element={<ChildAppPlaceholder appName="@prev-front/mf-peticoes" />} />
                                <Route path="/casos" element={<ChildAppPlaceholder appName="@prev-front/mf-peticoes" />} />
                                <Route path="/laudos" element={<ChildAppPlaceholder appName="@prev-front/mf-peticoes" />} />
                                {/* mf-calculos */}
                              </Route>
                            </Routes>
                          )
                        }
                    </PrevLayout>
                  </FormAnimationProvider>
              </Suspense>
            </PrevPlatformProvider>
        </Router>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  )
}
