import React from "react"
import { ArrowLeft, PrevidenciaristaLogo, usePrevTheme, Text, Button, Card, Col, Row } from "@prev-front/ui-components"
import { useContext } from "react"
import { FormAnimationContext } from "../contexts/FormAnimationContext"
import { AuditOutlined, CalculatorOutlined, DiffOutlined, MedicineBoxOutlined, MoneyCollectOutlined } from "@ant-design/icons"
import styled from "styled-components"

const calcOptions = [
    {
        icon: CalculatorOutlined,
        title: 'Cálculo de benefício',
        description: "Cálculo base, que serve para dar uma estimativa de qual será o valor que o beneficiário do INSS receberá.",
        onClick: () => window.open("/calculos/beneficio")
    },
    {
        icon: AuditOutlined,
        title: 'Cálculo de liquidação de sentença',
        description: "Seu objetivo é dar valor à uma sentença ilíquida (sem valor).",
        onClick: () => window.open("/calculos/liquidacao")
    },
    {
        icon: DiffOutlined,
        title: 'Calcular o valor da causa',
        description: "Estimativa do valor econômico da disputa que está sendo posta em juízo.",
        onClick: () => window.open("/calculos/valor-causa")
    },
    {
        icon: MoneyCollectOutlined,
        title: 'Calcular a RMI futura',
        description: "Faça um planejamento previdenciário adaptado ao momento atual do seu cliente.",
        onClick: () => window.open("/beneficio-por-incapacidade")
    },
    {
        icon: MedicineBoxOutlined,
        title: 'Cálculo benefício por incapacidade',
        description: "Fluxo de concessão ou restabelecimento de benefícios por incapacidade para processo eletrônico.",
        onClick: () => window.open("/beneficio-por-incapacidade")
    },
]

const StyledCard = styled(Card)`
  box-shadow: 0px 8px 12px 0px rgba(39, 39, 46, 0.15), 0px 0px 1px 0px rgba(39, 39, 46, 0.30);
  &:hover {
    box-shadow: 0px 12px 24px 0px rgba(39, 39, 46, 0.25), 0px 0px 3px 0px rgba(39, 39, 46, 0.5);
  }
`

export const CalcView = () => {
    const { theme } = usePrevTheme()
    const { calcView } = useContext(FormAnimationContext)
    return (
        <Col>
            <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                <Button
                    type="link"
                    icon={<ArrowLeft />}
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                    fontSize: '16px',
                    padding: '4px 0',
                    color: theme.color.text.default
                    }}
                    onClick={() => calcView.setShowCalcTab(false)}
                >
                    Voltar
                </Button>
                </Col>
                <Col>
                <PrevidenciaristaLogo />
                </Col>
                <Col style={{ width: '97px' }}></Col>
            </Row>
            <Row justify='center' style={{ marginTop: "60px"}}>
                <Text emphasis size="h4">Para prosseguir, escolha qual o tipo de cálculo você deseja</Text>
            </Row>
            <Row style={{ gap: "20px", marginTop: "30px"}} justify='center'>
                {
                    calcOptions.map((calcType) => (
                        <div onClick={() => calcType.onClick && calcType.onClick()}>
                            <StyledCard key={`${calcType.title}-card-template`} style={{ width: "350px", cursor: "pointer", minHeight: "180px"}}>
                                <calcType.icon style={{ color: theme.color.text.brand, fontSize: "32px", marginBottom: "5px" }} />
                                <Text emphasis size="h6">{calcType.title}</Text>
                                <Text>{calcType.description}</Text>
                            </StyledCard>
                        </div>
                    ))
                }
            </Row>
        </Col>
    )
}